<template>
  <div class="h-screen">
    <div class="h-screen flex flex-col overflow-hidden shadow">
      <div class="flex-grow h-full drawer">
        <input id="mobile-drawer" type="checkbox" class="drawer-toggle" />
        <div class="flex flex-col items-center flex-grow h-full text-center drawer-content">
          <div
            class="navbar flex w-full py-2 bg-neutral text-neutral-content justify-space-between"
          >
            <div class="flex-none">
              <label for="mobile-drawer" class="btn btn-circle btn-ghost drawer-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="inline-block w-6 h-6 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </label>
            </div>

            <div class="flex-1 mr-4">
              <img
                src="https://assets-cdn.wetix.net/images/wetix-logo-white.svg"
                alt="Wetix"
                class="w-14 -mt-1 md:visible"
              />
            </div>

            <!-- <div class="flex-shrink justify-center">
              <div class="form-control">
                <input type="text" placeholder="Search " class="input input-ghost sm:" />
              </div>
            </div>-->
            <div class="flex-shrink">
              <!-- SYNCING INDICATOR -->
              <button v-if="isSyncing" class="btn btn-circle btn-ghost">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 animate-bounce"
                  viewBox="0 0 20 20"
                  fill="orange"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <button class="btn btn-circle btn-ghost">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  :stroke="isOnline ? 'green' : 'red'"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
                  />
                </svg>
              </button>
              <button
                v-if="scanmode == 'camera'"
                @click="scanmode = 'hid'"
                class="btn btn-circle btn-ghost"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                  />
                </svg>
              </button>
              <button
                v-if="scanmode == 'hid'"
                @click="scanmode = 'camera'"
                class="btn btn-circle btn-ghost"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div
            class="flex flex-col items-center flex-1 w-full h-full pt-1 overflow-y-auto bg-base-200"
          >
            <!-- WELCOME SCREEN -->
            <div v-if="!screen || screen == 'welcome'" class="pa-2">
              <div class="text-center hero-content">
                <div class="max-w-md">
                  <h1 class="mb-5 text-5xl font-bold">Let's get your device set-up.</h1>
                  <p
                    class="mb-5"
                  >To link this device with an event to start scanning tickets, please login to your WeTix account and go to the 'setup scanners' screen..</p>
                  <button @click="initDeviceLinking" class="btn btn-primary">Continue</button>
                </div>
              </div>
            </div>
            <!-- BUILT-IN CAMERA SCANNER -->
            <div v-if="screen == 'scan' && scanmode == 'camera'" class="bg-pink-0 max-h-1/3 w-full">
              <CameraScanner />
            </div>
            <!-- USE EXTERNAL HID SCANNER -->
            <div v-if="screen == 'scan' && scanmode == 'hid'" class="bg-pink-0 w-full">
              <ScanTextField />
            </div>
            <!-- SCAN HISTORY -->
            <div
              v-if="screen == 'scan' && showScanHistory"
              class="bg-gray-50 w-full h-full overflow-auto"
            >
              <div class="my-1 text-sm text-capitalize font-semibold bg-white shadow">Scan History</div>
              <div
                v-for="(scan, n) in scanHistory"
                :key="n"
                class="flex mx-2 rounded-1"
                :class="scan.isValid ? 'bg-green-500' : 'bg-red-500'"
              >
                <div class="flex-shrink">
                  <label class="mx-3 font-mono font-semibold">{{ scan.n }}</label>
                </div>
                <div class="flex-grow">
                  <label class="mx-3 font-mono font-semibold">{{ scan.input }}</label>
                </div>
                <div class="flex-shrink pr-4">
                  {{ scan.isValid ? 'VALID' : 'INVALID' }}
                  <!-- <button
                    class="btn btn-sm btn-ghost mr-2"
                  >Edit</button>-->
                </div>
              </div>
              <button @click="scanHistory = []" class="btn btn-sm btn-ghost mr-2">RESET</button>
            </div>
          </div>
          <!-- FOOTER MENU -->
          <ul class="menu justify-center flex-none w-full px-3 horizontal bg-base-100">
            <li>
              <router-link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="inline-block w-6 h-6 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </router-link>
            </li>
            <li>
              <router-link to="/about">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                  />
                </svg>
              </router-link>
            </li>
            <li>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="drawer-side">
          <label for="mobile-drawer" class="drawer-overlay"></label>
          <ul class="p-4 overflow-y-auto pt-14 menu w-80 bg-base-100 text-base-content">
            <li>
              <a>Scanner Mode</a>
            </li>
            <li>
              <a>Camera Mode</a>
            </li>
            <li>
              <a>Settings</a>
            </li>
            <li>
              <a>Scan Logs</a>
            </li>
            <li>
              <a>Device Settings</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { ref, reactive } from '@vue/composition-api'
// import { QrcodeStream } from 'vue-qrcode-reader'
import CameraScanner from '../components/CameraScanner'
import ScanTextField from '../components/ScanTextField'
import { useOnline, useRefHistory } from '@vueuse/core'
import { useGlobalState } from '../store'
// import { UseFullscreen } from '@vueuse/components'

export default {

  components: {
    CameraScanner,
    ScanTextField
    // UseFullscreen
  },

  data() {
    return {
      loading: false,
      destroyed: false,
      isValid: undefined,
      camera: 'auto',
      result: null,
      error: null,
      scans: [],
      scanmode: 'hid',
      isSyncing: false,
      screen: 'welcome',
      showScanHistory: true,
      fallbackValidationRegex: null,
      isInitialised: false,

    }
  },

  computed: {
    validationPending() {
      return this.isValid === undefined
        && this.camera === 'off'
    },

    validationSuccess() {
      return this.isValid === true
    },

    validationFailure() {
      return this.isValid === false
    },

    // isOnline() {
    //   return useOnline()
    // }
  },

  methods: {

    async initDeviceLinking() {
      // This will launch a modal where the user enters a code from the admin panel or 
      // scans a QR code to register this deice with the back-end, 
      // and sync down a copy of the relevant event tickets for caching 
      // offline in firestore. For now, we fake this..
      this.isSyncing = true
      await this.timeout(2000)
      this.isInitialised = true
      this.fallbackValidationRegex = null // We will implement fall-back validation regex here some time.
      this.isSyncing = false
      this.scanmode = 'hid'
      this.screen = 'scan'

    },
    async reSync() {
      // Sync data with cloud 
      this.isSyncing = true
      await this.timeout(2000)
      this.isSyncing = false
    },

    async reload() {
      this.destroyed = true

      await this.$nextTick()

      this.destroyed = false
    },

    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  },
  setup() {
    const isOnline = useOnline()
    let scanHistory = useGlobalState()
    let history = useRefHistory(scanHistory)
    return {
      isOnline,
      scanHistory,
      history
    };
  },
}
</script>

