<template>
    <div id="cam-qr-scanner">
        <div class="max-h-1/3 w-full">
            <p class="error">{{ error }}</p>

            <qrcode-stream
                :key="_uid"
                :camera="camera"
                @decode="onDecode"
                @init="onInit"
                :track="paintOutline"
                class="border-solid border-4"
                :class="cameraBorderColor"
            >
                <div class="loading-indicator" v-if="loading">Loading...</div>
                <div v-if="validationSuccess" class="validation-success">This is a valid ticket</div>

                <div v-if="validationFailure" class="validation-failure">This is NOT a valid ticket!</div>

                <div v-if="validationPending" class="validation-pending">Validating...</div>
            </qrcode-stream>
        </div>
        <p class="decode-result">
            Last result:
            <b>{{ result }}</b>
        </p>
        <!-- <ul role="list" class="divide-y divide-gray-200">
                  <li v-for="(item, index) in scans" :key="index" class="py-4">{{ item }}</li>
        </ul>-->

        <div v-for="(item, index) in scans" :key="index" class="card shadow-lg">
            <div class="card-body">
                <h2 class="card-title">{{ item }}</h2>
                <p>Ticket Scan Details Here</p>
            </div>
        </div>
    </div>
</template>


<script>
// import { ref, reactive } from '@vue/composition-api'
import { QrcodeStream } from 'vue-qrcode-reader'
import { useOnline } from '@vueuse/core'

export default {

    components: { QrcodeStream },

    data() {
        return {
            loading: false,
            destroyed: false,
            isValid: undefined,
            camera: 'auto',
            result: null,
            error: null,
            scans: []
        }
    },

    computed: {
        validationPending() {
            return this.isValid === undefined
                && this.camera === 'off'
        },

        validationSuccess() {
            return this.isValid === true
        },

        validationFailure() {
            return this.isValid === false
        },
        cameraBorderColor() {
            if (this.isValid) {
                return 'border-green-500'
            } else if (this.isValid === false) {
                return 'border-red-500'
            } else {
                return 'border-blue-500'
            }
        }
        // isOnline() {
        //   return useOnline()
        // }
    },

    methods: {

        async onInit(promise) {
            this.loading = true

            try {
                await promise.then(this.resetValidationState)
            } catch (error) {
                console.error(error)
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            } finally {
                this.loading = false
                this.resetValidationState
            }
        },

        resetValidationState() {
            this.isValid = undefined
        },

        async onDecode(content) {
            this.result = content
            this.turnCameraOff()

            // pretend it's taking really long
            await this.timeout(1000)
            this.isValid = content.startsWith('T')

            this.scans.push(content)

            // some more delay, so users have time to read the message
            await this.timeout(2000)

            this.turnCameraOn()
        },

        turnCameraOn() {
            this.camera = 'auto'
        },

        turnCameraOff() {
            this.camera = 'off'
        },

        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },

        async reload() {
            this.destroyed = true

            await this.$nextTick()

            this.destroyed = false
        },

        timeout(ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        }
    },
    setup() {
        const isOnline = useOnline()
        return {
            isOnline,
        };
    },
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 10px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
.validation-success {
    color: green;
}
.validation-failure {
    color: red;
}
</style>