import Vue from "vue";
import './plugins/axios'
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);
import App from "./App.vue";
import VueQrcodeReader from "vue-qrcode-reader";
import "./assets/tailwind.css";
import './registerServiceWorker'
import router from './router'
Vue.use(VueQrcodeReader);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
