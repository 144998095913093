<template>
    <div id="scan-text-field-wrapper">
        <div class="w-full">
            <div class="form-control w-full">
                <div class="relative mx-1">
                    <input
                        v-model="scanInput"
                        ref="input"
                        type="text"
                        placeholder="Scan or type a ticket number"
                        class="w-full pr-16 input input-primary input-bordered"
                    />
                    <button
                        @click="playInvalidSound"
                        class="absolute top-0 right-0 rounded-l-none btn btn-primary"
                    >go</button>
                </div>
            </div>
            <!-- <div class="text-xs">
                <span>Charging: {{ charging }}</span> |
                <span>Charging Time: {{ chargingTime }}</span> |
                <span>Discharge Time: {{ dischargingTime }}</span> |
                <span>Level: {{ level }}</span>
            </div>-->
            <div v-if="showValidAlert" class="animate-pulse rounded-md bg-green-500 p-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="green"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-lg font-medium text-black">Valid ticket</p>
                    </div>
                    <!-- <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                            <button
                                type="button"
                                class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                                <span class="sr-only">Dismiss</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>-->
                </div>
            </div>
            <div v-if="showInvalidAlert" class="animate-bounce rounded-md bg-red-600 p-6">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                            />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-lg font-extrabold text-white-800">Invalid ticket</p>
                    </div>
                    <!-- <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                            <button
                                type="button"
                                class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                                <span class="sr-only">Dismiss</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
<!-- 
<script setup lang="ts">
import { ref } from 'vue-demi'
import { onStartTyping, useBattery } from '@vueuse/core'

const { charging, chargingTime, dischargingTime, level } = useBattery()

const batteryChargingTime = chargingTime.value





</script> -->

<script lang="ts">
import { ref } from 'vue-demi'
import { onStartTyping, onKeyStroke, useStorage, useVibrate } from '@vueuse/core'
import { useGlobalState } from '../store'
import { useSound } from '@vueuse/sound'
// import invalidSound from '../assets/invalid-sound.mp3'
// import validSound from '../assets/valid-sound.mp3'
// import { useOnline, onStartTyping } from '@vueuse/core'

export default {
    components: {},
    data() {
        return {
            // scanInput: '',
            loading: false,
            destroyed: false,
            // isValid: undefined,
            result: null,
            error: null,
            // showValidAlert: false,
            // showInvalidAlert: false,
            // scanHistory: []
        }
    },

    computed: {
        validationPending() {
            return this.isValid === undefined
                && this.camera === 'off'
        },

        validationSuccess() {
            return this.isValid === true
        },

        validationFailure() {
            return this.isValid === false
        },
        cameraBorderColor() {
            if (this.isValid) {
                return 'border-green-500'
            } else if (this.isValid === false) {
                return 'border-red-500'
            } else {
                return 'border-blue-500'
            }
        }
        // isOnline() {
        //   return useOnline()
        // }
    },

    methods: {


        async onInit() {
            this.loading = true


        },

        resetValidationState() {
            this.isValid = undefined
        },

        async onDecode() {

        },



        timeout(ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        }
    },
    setup() {




        const input = ref(null)

        const scanInput = ref('')
        const showValidAlert = ref(false)
        const showInvalidAlert = ref(false)
        const scanHistory = useGlobalState()
        const scanNumber = useStorage('wetix-scan-number', 1)
        let isValid = false

        const validVibrate = async () => {
            console.log(`VIBRATING Valid vibrate...`)
            const { vibrate, isSupported } = useVibrate({ pattern: [100, 100] })
            if (!isSupported) return console.log(`Vibrate is not supported, this would be a VALID vibrate`)
            vibrate()
        }

        const invalidVibrate = async () => {
            console.warn(`VIBRATING Invalid vibrate...`)
            const { vibrate, isSupported } = useVibrate({ pattern: [1000, 100, 2000] })
            if (!isSupported) return console.log(`Vibrate is not supported, this would be a INVALID vibrate`)
            vibrate()
        }

        const playValidSound = async () => {
            console.log(`PLAYING Valid sound...`)
            const { play } = useSound('../assets/valid-sound.mp3')
            return play()
        }

        const playInvalidSound = async () => {
            console.log(`PLAYING Invalid sound...`)
            const { play } = useSound('../assets/invalid-sound.mp3')
            return play()
        }

        const validateInput = async () => {

            console.log(`Validating input...`)
            const i = scanInput.value
            if (i.startsWith('22')) {
                isValid = true
            } else if (i.startsWith('W')) {
                isValid = true
            } else {
                isValid = false
            }

            // Make the device vibrate 
            if (isValid) validVibrate()
            if (!isValid) invalidVibrate()

            // Play a sound
            if (isValid) playValidSound()
            if (!isValid) playInvalidSound()

            // Add to history
            scanHistory.value.unshift({ input: i, isValid, n: scanNumber.value })
            scanNumber.value = scanNumber.value + 1
            scanInput.value = '' // Reset ready for next scan


            console.log(`Validation result: `, isValid)
            if (isValid) {
                // Show alert for some seconds 
                showValidAlert.value = true
                await new Promise(resolve => setTimeout(resolve, 1500))
                showValidAlert.value = false
            } else {
                showInvalidAlert.value = true
                await new Promise(resolve => setTimeout(resolve, 3000))
                showInvalidAlert.value = false
            }

            //


            console.log(`Resetting scan state...`)
            scanInput.value = ''
            isValid = false
        }

        onStartTyping(() => {
            if (!input.value.active)
                input.value.focus()
        })

        onKeyStroke('Enter', (e) => {
            e.preventDefault()
            validateInput()
        })



        return {
            input,
            showValidAlert,
            showInvalidAlert,
            scanInput,
            scanHistory,
            scanNumber,
            playValidSound,
            playInvalidSound,
            validateInput
        }
    }

}
</script>

